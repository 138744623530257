import { TimingFunction } from './TimingFunction';
import { bezierCurve } from './bezierCurve';

export class CubicBezier extends TimingFunction {
	_impl: (value: number) => number;

	constructor(args1: (string | number)[]) {
		super(args1.map(String));
		let args: number[] = args1.map(Number);

		this._impl = bezierCurve(args[0], args[1], args[2], args[3]);
	}
	
	_value(percent: number) {
		return this._impl(percent);
	}
	
	alwaysIncreasing = false;
}

TimingFunction.functions['cubic-bezier'] = CubicBezier;