import { TimingFunction } from "./TimingFunction";

export type Step = {
	start: number,
	end: number,
	value: number
};

export class Steps extends TimingFunction {
	count: number;
	which: string;
	bounds: Step[];
	
	constructor(args: (string | number)[]) {
		super(args.map(String));
		var count = Number(args[0]);
		var which = String(args[1]);
		this.count = count;
		this.which = which;
		this.bounds = [];
		if (which == 'start') {
			this.bounds[0] = { start: 0, end: 0, value: 0 };
			for (var i = 0; i < count; i++) {
				this.bounds[this.bounds.length] = { start: (i / count), end: (i + 1) / count, value: (i + 1) / count };
			}
		} else {
			for (var i = 0; i < count; i++) {
				this.bounds[this.bounds.length] = { start: (i / count), end: (i + 1) / count, value: i / count };
			}
			this.bounds[this.bounds.length - 1].end = 0.99999999;
			this.bounds[this.bounds.length] = { start: 1, end: 1, value: 1 };
		}
	}
	
	_value(percent: number) {
		percent = Math.min(percent, 1);
		percent = Math.max(percent, 0);
		return this.bounds.filter(v => v.start >= percent && v.end <= percent)[0].value;
	}
	alwaysIncreasing = true;
}

TimingFunction.functions.steps = Steps;
