import { CubicBezier } from "./CubicBezier";
import { TimingFunction } from "./TimingFunction";

class EaseInOut extends CubicBezier {
	constructor() { super([0.42, 0, 0.58, 1]); }

	alwaysIncreasing = true;
}

TimingFunction.functions['ease-in-out'] = EaseInOut

export { EaseInOut };
