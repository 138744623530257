import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import EventCell from './EventCell'
import { isSelected } from './utils/selection'
import { Context } from './Styles';

/* eslint-disable react/prop-types */
export default {
  propTypes: {
    slotMetrics: PropTypes.object.isRequired,

    selected: PropTypes.object,
    isAllDay: PropTypes.bool,

    accessors: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,

    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
  },

  defaultProps: {
    segments: [],
    selected: {},
  },

  renderEvent(props, event) {
    let {
      selected,
      isAllDay: _,
      accessors,
      getters,
      onSelect,
      onDoubleClick,
      localizer,
      slotMetrics,
      components,
    } = props

    let continuesPrior = slotMetrics.continuesPrior(event)
    let continuesAfter = slotMetrics.continuesAfter(event)

    return (
      <EventCell
        event={event}
        getters={getters}
        localizer={localizer}
        accessors={accessors}
        components={components}
        onSelect={onSelect}
        onDoubleClick={onDoubleClick}
        continuesPrior={continuesPrior}
        continuesAfter={continuesAfter}
        slotStart={slotMetrics.first}
        slotEnd={slotMetrics.last}
        selected={isSelected(event, selected)}
      />
    )
  },

  renderSpan(slots, len, key, content = ' ') {
    let per = (Math.abs(len) / slots) * 100 + '%'

    return (
      <EventRowSpan key={key} per={per}>{content}</EventRowSpan>
    )
  },
}

const EventRowSpan = props => {
	let classes = useContext(Context);
	return <div className={classes.rowSegment} style={{ WebkitFlexBasis: props.per, flexBasis: props.per, maxWidth: props.per }}>{props.children}</div>;
}

