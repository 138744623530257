import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import yellow from '@material-ui/core/colors/yellow';

const Context = React.createContext({
	toolbar: 'rbc-toolbar',
	btnGroup: 'rbc-btn-group',
	toolbarLabel: 'rbc-toolbar-label',
	active: 'rbc-active',
	timeslotGroup: 'rbc-timeslot-group',
	timeslot: 'rbc-timeslot',
	label: 'rbc-label',
	now: 'rbc-now',
	timeGutter: 'rbc-time-gutter'
});

export { Context };

const absFull = {
	position: 'absolute',
	overflow: 'hidden',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0
};

const ellipsis = {
	display: 'block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
};

const useStyles = makeStyles(theme => {
	const $outOfRangeColor = theme.palette.action.disabled;
	const $outOfRangeBgColor = theme.palette.action.disabledBackground;
	const $calendarBorder = theme.palette.grey[400];
	const $agendaDateBorder = theme.palette.grey[theme.palette.type == 'light' ? 800 : 200]
	const $cellBorder = theme.palette.grey[400];
	const $lightCellBorder = theme.palette.grey[300];
	const $timeSelectionColor = theme.palette.common.white;
	const $timeSelectionBgColor = theme.palette.action.active;
	const $dateSelectionBgColor = theme.palette.action.selected;
	const $eventBg = theme.palette.primary.main;
	const $darkEventBg = theme.palette.primary.dark;
	const $eventBorder = theme.palette.primary.dark;
	const $eventOutline = theme.palette.primary.light;
	const $eventColor = theme.palette.primary.contrastText;
	const $eventBorderRadius = theme.shape.borderRadius;
	const $eventPadding = `${theme.spacing(0.5)}px ${theme.spacing(1)}px`;
	const $eventZindex = 4;
	const $btnColor = theme.palette.primary.main;
	const $btnBg = theme.palette.primary.contrastText;
	const $activeBtnBg = theme.palette.grey[theme.palette.type == 'light' ? 100 : 900];
	const $activeHoverBtnBg = theme.palette.grey[theme.palette.type == 'light' ? 200 : 800];
	const $btnBorder = theme.palette.type == 'light' ? theme.palette.grey[400] : theme.palette.grey[800];
	const $activeBtnBorder = theme.palette.type == 'light' ? theme.palette.grey[500] : theme.palette.grey[700];
	const $activeHoverBtnBorder = theme.palette.grey[600];
	const $currentTimeColor = theme.palette.secondary.main;
	const $todayHighlightBg = yellow[100];

	return ({
		// --- agenda.scss ---

		// .rbc-agenda-view
		agendaView: {
			display: 'flex',
			flexDirection: 'column',
			// flex: '1 0 0',
			flex: '0 0 auto',
			overflow: 'visible',

			'& table$agendaTable': {
				width: '100%',
				// border: `1px solid ${$cellBorder}`,
				borderSpacing: 0,
				borderCollapse: 'collapse',
				'& > thead': {
					display: 'none'
				},
				'& tbody > tr > td': {
					padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
					verticalAlign: 'top'
				},

				'& tbody > tr > td + td': {
					// borderLeft: `1px solid ${$cellBorder}`
				},

				'$rtl &': {
					'& tbody > tr > td + td': {
						borderLeft: 0,
						// borderRight: `1px solid ${$cellBorder}`
					}
				},

				'& tbody > tr + tr': {
					borderTop: `1px solid ${$cellBorder}`,
				},
				'& tbody > tr > td[rowspan], & tbody > tr > td[rowspan] ~ td': {
					borderTop: `1px solid ${$agendaDateBorder}`
				},
				'& thead > tr > th': {
					padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
					textAlign: 'left',
					borderBottom: `1px solid ${$cellBorder}`,
					'$rtl &': {
						textAlign: 'right'
					},
					
				}
			}
		},

		// .rbc-agenda-time-cell
		agendaTimeCell: {
			whiteSpace: 'nowrap',
			textTransform: 'lowercase',
			'& $continuesAfter:after': {
				content: "' »'"
			},
			'& $continuesPrior:before': {
				content: "'« '"
			}
		},

		// .rbc-agenda-date-cell
		agendaDateCell: {
			whiteSpace: 'nowrap',
			...theme.typography.body1
		},

		// .rbc-agenda-event-cell
		agendaEventCell: {
			width: '100%'
		},

		// --- event.scss ---
		// .rbc-event
		event: {
			border: 'none',
			boxSizing: 'border-box',
			boxShadow: 'none',
			margin: 0,
			padding: $eventPadding,
			backgroundColor: $eventBg,
			borderRadius: $eventBorderRadius,
			color: $eventColor,
			cursor: 'pointer',
			width: '100%',
			textAlign: 'left',

			'$slotSelecting &': {
				cursor: 'inherit',
				pointerEvents: 'none'
			},

			'&$selected': {
				backgroundColor: $darkEventBg
			},

			'&:focus': {
				outline: `${theme.spacing(0.5)}px auto ${$eventOutline}`
			}
		},

		// .rbc-event-label
		eventLabel: {
			...ellipsis,
			fontSize: '80%'
		},

		// .rbc-event-overlaps
		eventOverlaps: {
			boxShadow: theme.shadows[1]
		},

		// .rbc-event-continues-prior
		eventContinuesPrior: {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		},

		// .rbc-event-continues-after
		eventContinuesAfter: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},

		// .rbc-event-continues-earlier
		eventContinuesEarlier: {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0
		},

		// .rbc-event-continues-later
		eventContinuesLater: {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0
		},

		// --- month.scss ---
		// .rbc-row
		row: {
			display: 'flex',
			flexDirection: 'row'
		},

		// .rbc-row-segment
		rowSegment: {
			padding: '0 1px 1px 1px',
			'& $eventContent': {
				...ellipsis
			}
		},

		// .rbc-selected-cell
		selectedCell: {
			backgroundColor: $dateSelectionBgColor
		},

		// .rbc-show-more
		showMore: {
			...ellipsis,
			backgroundColor: 'rgba(255, 255, 255, .3)',
			zIndex: $eventZindex,
			fontWeight: 'bold',
			fontSize: '85%',
			height: 'auto',
			lineHeight: 'normal'
		},

		// .rbc-month-view
		monthView: {
			position: 'relative',
			border: `1px solid ${$calendarBorder}`,
			display: 'flex',
			flexDirection: 'column',
			flex: '1 0 0',
			width: '100%',
			userSelect: 'none',
			height: '100%'
		},

		// .rbc-month-header
		monthHeader: {
			display: 'flex',
			flexDirection: 'row'
		},

		// .rbc-month-row
		monthRow: {
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',
			flex: '1 0 0',
			flexBasis: 'auto',
			minHeight: '120px',
			overflow: 'hidden',
			height: '100%',
			'& + $monthRow': {
				borderTop: `1px solid ${$cellBorder}`
			},
			'& $rowContent $row:last-child': {
				paddingBottom: theme.spacing(1)
			}
		},

		// .rbc-date-cell
		dateCell: {
			flex: '1 1 0',
			minWidth: 0,
			paddingRight: theme.spacing(0.5),
			textAlign: 'right',
			'& $now': {
				fontWeight: 'bold'
			},
			'& > a': {
				'&, &:active, &:visited': {
					color: 'inherit',
					textDecoration: 'none'
				}
			}
		},

		// .rbc-row-bg
		rowBg: {
			...absFull,
			display: 'flex',
			flexDirection: 'row',
			flex: '1 0 0',
			overflow: 'hidden'
		},

		// .rbc-day-bg
		dayBg: {
			flex: '1 0 0%',
			'& + $dayBg': {
				borderLeft: `1px solid ${$cellBorder}`
			},
			'$rtl & + $dayBg': {
				borderLeft: 0,
				borderRight: `1px solid ${$cellBorder}`
			}
		},

		// .rbc-overlay
		overlay: {
			position: 'absolute',
			zIndex: $eventZindex + 1,
			border: `1px solid ${theme.palette.grey[300]}`,
			backgroundColor: theme.palette.common.white,
			boxShadow: theme.shadows[4],
			padding: theme.spacing(1.5),
			'& > * + *': {
				marginTop: '-1px'
			}
		},

		// .rbc-overlay-header
		overlayHeader: {
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
			margin: `${theme.spacing(-1.5)}px ${theme.spacing(-1.5)}px ${theme.spacing(0.5)}px ${theme.spacing(-1.5)}px`,
			padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
		},

		// --- reset.scss ---
		// .rbc-btn
		btn: {
			color: 'inherit',
			font: 'inherit',
			margin: 0,
			'button&': {
				overflow: 'visible',
				textTransform: 'none',
				WebkitAppearance: 'button',
				cursor: 'pointer'
			},
			'button[disabled]&': {
				cursor: 'not-allowed'
			}
		},

		// .rbc-input
		input: {
			'button&::-moz-focus-inner': {
				border: 0,
				padding: 0
			}
		},

		// --- styles.scss ---
		// .rbc-calendar
		calendar: {
			boxSizing: 'border-box',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
			'& *, & *:before, & *:after': {
				boxSizing: 'inherit'
			}
		},

		// .rbc-abs-full
		absFull: {
			...absFull
		},

		// .rbc-ellipsis
		ellipsis: {
			...ellipsis
		},

		// .rtl, .rbc-rtl
		rtl: {
			direction: 'rtl'
		},

		// .rbc-off-range
		offRange: {
			color: $outOfRangeColor
		},

		// .rbc-off-range-bg
		offRangeBg: {
			backgroundColor: $outOfRangeBgColor
		},

		// .rbc-header
		header: {
			overflow: 'hidden',
			flex: '1 0 0%',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			padding: `0 ${theme.spacing(0.5)}px`,
			textAlign: 'center',
			verticalAlign: 'middle',
			fontWeight: 'bold',
			fontSize: '90%',
			minHeight: 0,
			borderBottom: `1px solid ${$cellBorder}`,
			'& + $header': {
				borderLeft: `1px solid ${$cellBorder}`
			},
			'$rtl & + $header': {
				borderLeft: 0,
				borderRight: `1px solid ${$cellBorder}`
			},
			'& > a': {
				'&, &:active, &:visited': {
					color: 'inherit',
					textDecoration: 'none'
				}
			}
		},

		// .rbc-row-content
		rowContent: {
			position: 'relative',
			userSelect: 'none',
			zIndex: 4
		},

		// .rbc-today
		today: {
			backgroundColor: $todayHighlightBg
		},

		// --- time-column.scss ---
		// .rbc-time-column
		timeColumn: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100%',
			'& $timeslotGroup': {
				flex: 1
			}
		},

		// .rbc-timeslot-group
		timeslotGroup: {
			borderBottom: `1px solid ${$cellBorder}`,
			minHeight: theme.spacing(5),
			display: 'flex',
			flexFlow: 'column nowrap'
		},

		// .rbc-time-gutter
		timeGutter: {
			flex: 'none'
		},

		// .rbc-header-gutter
		headerGutter: {
			flex: 'none'
		},

		// .rbc-label
		label: {
			padding: `0 ${theme.spacing(0.5)}px`
		},

		// .rbc-day-slot
		daySlot: {
			position: 'relative',
			'& $eventsContainer': {
				bottom: 0,
				left: 0,
				position: 'absolute',
				right: 0,
				top: 0,
				marginRight: theme.spacing(1.5),
				'$rtl &': {
					left: theme.spacing(1.5),
					marginRight: 0
				},
			},
			'& $event': {
				border: `1px solid ${$eventBorder}`,
				display: 'flex',
				maxHeight: '100%',
				minHeight: theme.spacing(2.5),
				flexFlow: 'column wrap',
				alignItems: 'flex-start',
				overflow: 'hidden',
				position: 'absolute'
			},

			'& $eventLabel': {
				flex: 'none',
				paddingRight: theme.spacing(1),
				width: 'auto'
			},

			'& $eventContent': {
				width: '100%',
				flex: '1 1 0',
				wordWrap: 'break-word',
				lineHeight: 1,
				height: '100%',
				minHeight: '1em'
			},

			'& $timeSlot': {
				borderTop: `1px solid ${$lightCellBorder}`
			}
		},

		// .rbc-time-view-resources
		timeViewResources: {
			'& $timeGutter, & $timeHeaderGutter': {
				position: 'sticky',
				left: 0,
				background: theme.palette.common.white,
				borderRight: `1px solid ${$cellBorder}`,
				zIndex: 10,
				marginRight: -1
			},
			'& $timeHeader': {
				overflow: 'hidden'
			},
			'& $timeHeaderContent': {
				minWidth: 'auto',
				flex: '1 0 0',
				flexBasis: '0px'
			},
			'& $timeHeaderCellSingleDay': {
				display: 'none'
			},
			'& $daySlot': {
				minWidth: 140
			},
			'& $header, & $dayBg': {
				width: `140px`,
				flex: '1 1 0',
				flexBasis: '0px'
			}
		},

		// .rbc-time-slot
		timeSlot: {
			flex: '1 0 0',
			'&$now': {
				fontWeight: 'bold'
			}
		},

		// .rbc-day-header
		dayHeader: {
			textAlign: 'center'
		},

		// --- time-grid.scss ---
		// .rbc-slot-selection
		slotSelection: {
			zIndex: 10,
			position: 'absolute',
			backgroundColor: $timeSelectionBgColor,
			color: $timeSelectionColor,
			fontSize: '75%',
			width: '100%',
			padding: theme.spacing(0.5)
		},

		// .rbc-slot-selecting
		slotSelecting: {
			cursor: 'move'
		},

		// .rbc-time-view
		timeView: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			border: `1px solid ${$calendarBorder}`,
			minHeight: 0,
			'& $timeGutter': {
				whiteSpace: 'nowrap'
			},
			'& $alldayCell': {
				boxSizing: 'content-box',
				width: '100%',
				height: '100%',
				position: 'relative'
			},
			'& $alldayCell + $alldayCell': {
				borderLeft: `1px solid ${$cellBorder}`
			},
			'& $alldayEvents': {
				position: 'relative',
				zIndex: 4
			},
			'& $row': {
				boxSizing: 'border-box',
				minHeight: theme.spacing(2.5)
			}
		},

		// .rbc-allday-events
		alldayEvents: {},
		
		// .rbc-time-header
		timeHeader: {
			display: 'flex',
			flex: '0 0 auto',
			flexDirection: 'row',
			'&$overflowing': {
				borderRight: `1px solid ${$cellBorder}`
			},
			'$rtl &$overflowing': {
				borderRightWidth: 0,
				borderLeft: `1px solid ${$cellBorder}`
			},
			'& > $row:first-child': {
				borderBottom: `1px solid ${$cellBorder}`
			},
			'& > $row$rowResource': {
				borderBottom: `1px solid ${$cellBorder}`
			}
		},

		// .rbc-time-header-cell-single-day
		timeHeaderCellSingleDay: {
			display: 'none'
		},

		// .rbc-time-header-content
		timeHeaderContent: {
			flex: 1,
			display: 'flex',
			minWidth: 0,
			flexDirection: 'column',
			borderLeft: `1px solid ${$cellBorder}`,
			'$rtl &': {
				borderLeftWidth: 0,
				borderRight: `1px solid ${$cellBorder}`
			},
			'& + $timeHeaderContent': {
				marginLeft: -1
			},
			'& > $row$rowResource': {
				borderBottom: `1px solid ${$cellBorder}`,
				flexShrink: 0
			}
		},

		// .rbc-time-content
		timeContent: {
			display: 'flex',
			flex: '1 0 0%',
			alignItems: 'flex-start',
			width: '100%',
			borderTop: `2px solid ${$calendarBorder}`,
			overflowY: 'auto',
			position: 'relative',
			'& > $timeGutter': {
				flex: 'none'
			},
			'& > * + * > *': {
				borderLeft: `1px solid ${$cellBorder}`
			},
			'$rtl & > * + * > *': {
				borderLeftWidth: 0,
				borderRight: `1px solid ${$cellBorder}`
			},
			'& > $daySlot': {
				width: '100%',
				userSelect: 'none'
			}
		},

		// .rbc-current-time-indicator
		currentTimeIndicator: {
			position: 'absolute',
			zIndex: 3,
			left: 0,
			right: 0,
			height: 1,
			backgroundColor: $currentTimeColor,
			pointerEvents: 'none'
		},

		// --- toolbar.scss ---
		// .rbc-toolbar
		toolbar: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: theme.spacing(1),
			fontSize: '1rem',
			'& $toolbarLabel': {
				flexGrow: 1,
				padding: `0 ${theme.spacing(1)}`,
				textAlign: 'center'
			},
			/*
			'& button': {
				color: $btnColor,
				display: 'inline-block',
				margin: 0,
				textAlign: 'center',
				verticalAlign: 'middle',
				background: 'none',
				backgroundImage: 'none',
				border: `1px solid ${$btnBorder}`,
				padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
				borderRadius: theme.shape.borderRadius,
				lineHeight: 'normal',
				whiteSpace: 'nowrap',

				'&:active, &$active': {
					backgroundImage: 'none',
					boxShadow: `inset 0 3px 5px rgba(0,0,0,.125)`,
					backgroundColor: $activeBtnBg,
					borderColor: $activeBtnBorder,
					'&:hover, &:focus': {
						color: $btnColor,
						backgroundColor: $activeHoverBtnBg,
						borderColor: $activeHoverBtnBorder
					}
				},
				'&:hover, &:focus': {
					color: $activeBtnBg,
					backgroundColor: $activeBtnBg,
					borderColor: $activeBtnBorder
				}
			}
			*/
		},

		// .rbc-btn-group
		btnGroup: {
			/*
			display: 'inline-block',
			whiteSpace: 'nowrap',
			'& > button:first-child:not(:last-child)': {
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0
			},
			'& > button:last-child:not(:first-child)': {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0
			},
			'$rtl & > button:first-child:not(:last-child)': {
				borderRadius: theme.shape.borderRadius,
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0
			},
			'$rtl & > button:last-child:not(:first-child)': {
				borderRadius: theme.shape.borderRadius,
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0
			},
			'& > button:not(:first-child):not(:last-child)': {
				borderRadius: 0
			},
			'& button + button': {
				marginLeft: -1
			},
			'$rtl & button + button': {
				marginLeft: 0,
				marginRight: -1
			},
			'& + $btnGroup, & + button': {
				marginLeft: 10
			}
			*/
		},

		// Extras
		// .rbc-toolbar-label
		toolbarLabel: {},

		// .rbc-active
		active: {},

		// .rbc-timeslot
		timeslot: {},


		// .rbc-now
		now: {},

		// .rbc-allday-cell
		alldayCell: {},

		// .rbc-overflowing
		overflowing: {},

		// .rbc-time-header-gutter
		timeHeaderGutter: {},

		// .rbc-row-resource
		rowResource: {},

		// .rbc-time-header-cell
		timeHeaderCell: {},

		// .rbc-event-content
		eventContent: {},


		// .rbc-selected
		selected: {},

		// .rbc-current
		current: {},

		// .rbc-event-allday
		eventAllday: {},


		// .rbc-events-container
		eventsContainer: {},


		// .rbc-agenda-table
		agendaTable: {},

		// .rbc-agenda-content
		agendaContent: {},

		// .rbc-agenda-empty
		agendaEmpty: {},

		// .rbc-continues-prior
		continuesPrior: {},

		// .rbc-continues-after
		continuesAfter: {},

		// .rbc-header-overflowing
		headerOverflowing: {}
	})
}, { name: 'BigCalendar' });

export default useStyles;
