import { makeStyles } from '@material-ui/styles';
import { ExtendedTheme } from '@sightworks/theme';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
	root: {},
	disabledCarouselContainer: {},
	carouselContent: {
		order: 0,
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
		justifyContent: 'flex-start',
		overflow: 'hidden'
	},
	carouselItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		transform: 'translateX(-100%)'
	},
	carouselContainer: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative'
	},
	carouselWithoutControls: {},
	carouselWithIndicators: {},
	carouselCycle: {
		minWidth: 'min-content',
		flex: '0 0 auto',
		position: 'absolute',
		top: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		zIndex: 3,
		overflow: 'hidden',
		'&:empty': { display: 'none' },
	},
	carouselCyclePrev: {
		left: 0,
	},
	carouselCycleNext: {
		right: 0
	},
	left: {},
	outside: {},
	outline: {},
	carouselIndicatorBox: {
		display: 'none'
	},
	iconButton: {},
	carouselIndicatorItem: {},
	notInfinite: {},
	transitionSlide: {},
	transitionFade: {},
	carouselContentCentered: {
		'& $carouselItem': {
			transform: 'none'
		},
		paddingLeft: '0 !important',
		justifyContent: 'center'
	},
	carouselContentLeft: {
		'& $carouselItem': {
			transform: 'none'
		}
	},
	previousSpace: {},
	placeholderPreviousSpace: {},
	placeholderNextSpace: {},
	right: {},
	active: {}
}), { name: 'SWCarousel' });

export { useStyles };
