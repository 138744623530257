import React, { Component } from 'react';

export class ErrorBoundary extends Component<React.PropsWithChildren<{}>, { errorStack: string }> {
	state: { errorStack: string } = { errorStack: null };

	static getDerivedStateFromError(error: any) {
		if (error instanceof Error) {
			return { errorStack: error.stack }
		}
		return { errorStack: String(error) }
	}

	componentDidCatch(error: any, info: any) {
		console.log({ error, info });
	}

	render() {
		if (this.state.errorStack) return <pre>{this.state.errorStack}</pre>
		return this.props.children;
	}
}
