import { StepCallback } from "./StepCallback";
import { EndCallback } from "./EndCallback";
import { TimingFunction } from "./TimingFunction";

export class Animation {
	timingFunc: TimingFunction;
	duration: number;
	callback: StepCallback;
	finishCallback: EndCallback;
	ended: boolean;
	startTime: number;

	constructor(duration: number, func: string, callback: StepCallback, finishCallback: EndCallback = (value: boolean) => {}) {
		this.timingFunc = TimingFunction.for(func);
		this.duration = duration;
		this.callback = callback;
		this.finishCallback = finishCallback;
		this.ended = true;
	}
	
	start() {
		this.ended = false;
		this.startTime = Date.now();
		this.run();
	}
	
	stop() {
		if (!this.ended) {
			this.ended = true;
			this.finishCallback(false);
		}
	}
	
	run() {
		if (this.ended) return;
		
		var T = Date.now() - this.startTime;
		var P;
		if (!this.duration) {
			P = 1;
		} else {
			P = T / this.duration;
			P = Math.min(1, P);
			P = Math.max(0, P);
		}
		if (isNaN(P)) P = 1;
		
		this.callback(this.timingFunc.value(P));
		if (P < 1) {
			requestAnimationFrame(() => this.run());
		} else {
			this.ended = true;
			this.finishCallback(true);
		}
	}
}
