import React, { useContext, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';
import clsx from 'clsx';
import DownloadButtonProps from './props';

const FileDownloadBlock = ({ classes, file, image, fileName, fileSize, button, ...props }: DownloadButtonProps, ref: React.Ref<any>): JSX.Element => {
	return (
		<a className={classes.root} href={file} target='_blank' download>
			{image && <img {...image} className={clsx(classes.image, image?.className)}/>}
			{fileName && <span className={classes.fileName}>{fileName}</span>}
			{fileSize && <span className={classes.fileSize}>{fileSize}</span>}
			{button && <Button className={classes.button} {...button.props} component="div"/>}
		</a>
	)
};

export default ThemeLoader(
	StyleLoader(
		FileDownloadBlock,
		makeStyles(
			theme => ({
				root: {
					display: 'inline-grid',
					gridTemplateColumns: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					justifyItems: 'center',
					textAlign: 'center',
					gridGap: theme.spacing(1),
					textDecoration: 'none',
					color: theme.palette.text.primary
				},
				image: {
					maxWidth: '100%'
				},
				fileName: {
					fontWeight: 'bold'
				},
				fileSize: {},
				button: {}
			}),
			{ name: 'SWFileDownloadButton' }
		)
	)
);
