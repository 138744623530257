import React, { useState, useEffect, useCallback, useReducer, useContext } from 'react';
import ReactDOM from 'react-dom';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useBreakpoint } from '@sightworks/block';
import { QuestionTypes } from '@sightworks/form';
import { getChildren, flattenChildren, OpaqueContent } from '@sightworks/block';
import { ActionContext } from '@sightworks/block';
import { CSSProperties } from '@material-ui/styles';
import FormProps from './props';
import Question from '../../questions/body';
import { FieldProps } from '../../registry';
import { UploadInfo } from '../../question-types/fileUploadField/question';

type RecaptchaOptions = {
	sitekey: string, theme: string, callback: ((response: any) => void), 'expired-callback': (() => void)
}
declare var grecaptcha: {
	render(node: HTMLElement, options?: RecaptchaOptions): void
};
declare var recaptchaState: Promise<void>;

const Question = (props: FieldProps) => {
	if (QuestionTypes[props.type]) {
		return React.createElement(QuestionTypes[props.type].field, props);
	}
	return <p>FIXME: {props.type}</p>
}

async function submitForm(url: string, catalogItem: string, form: string, formContents: Record<string, FormField>, 
		dispatch: React.Dispatch<FormAction>, 
		recaptchaResponse: any) {
	let fc: Record<string, string | UploadInfo> = {};
	for (let [field, value] of Object.entries(formContents)) {
		if (typeof value == 'object') {
			let v = await value.getValue(`${url}/upload/${field}`);
			fc[field] = v;
		} else {
			fc[field] = value;
		}
	}
	let result = await fetch(`${url}/submit`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			catalogItem,
			form,
			answers: fc,
			recaptchaResponse
		})
	});
	if (!(result.status == 200 || result.status == 400)) {
		dispatch({
			action: 'error',
			data: {
				general: true,
				message: 'An error occurred submitting your form.'
			}
		});
		return;
	}

	let body = await result.json();
	if (result.status == 400)
		dispatch({
			action: 'error',
			data: body
		});
	else
		dispatch({
			action: 'success'
		});
}

export type FormStatus = 'READY' | 'LOADING' | 'SUBMITTING' | 'DONE';
export type FormState = { 
	status: FormStatus;
	error: ClientError | ServerError;
	form: Form;
	formContents: Record<string, FormField>;
	recaptchaResponse: any; 
};
export type FormField = { toString(): string; getValue(value: string): Promise<UploadInfo> } | string;

export type FormAction = SetFieldAction | SetFormAction | SubmitAction | SuccessAction | ErrorAction | RecaptchaAction;

type SubmitAction = {
	action: 'submit';
	data: {
		dispatch: React.Dispatch<FormAction>;
		responseRoot: string;
		catalogItem: string;
	}
}
type SetFieldAction = {
	action: 'setField';
	data: Record<string, FormField>
}
type SetFormAction = {
	action: 'setForm';
	data: Form;
}
type Form = {
	id: string;
	title: string;
	questions: Question[];
}

type SuccessAction = {
	action: 'success',
	data?: null;
}

type ErrorAction = {
	action: 'error',
	data: ClientError | ServerError;
}
type ClientError = {
	general: true;
	message: string;
}
type RecaptchaAction = {
	action: 'captcha',
	data: any;
}

export type ServerError = InvalidCaptcha | NotFound | EmptyField | InvalidValue;
type InvalidCaptcha = {
	errorCode: 'INVALID_CAPTCHA';
	error: string;
	response?: any;
}
type NotFound = {
	errorCode: 'NOT_FOUND';
	error: string;
}
export type EmptyField = {
	errorCode: 'EMPTY_FIELD',
	error: string,
	question: string;
}
export type InvalidValue = {
	errorCode: 'INVALID_VALUE',
	error: string;
	question: string;
}

const FormReducer = (state: FormState, action: FormAction): FormState => {
	switch (action.action) {
		case 'setField': {
			if (state.status == 'READY') {
				return {
					...state,
					formContents: { ...state.formContents, ...action.data }
				};
			}
			return state;
		}

		case 'setForm': {
			if (state.status == 'LOADING') {
				return {
					...state,
					form: action.data,
					status: 'READY',
					error: null
				}
			};
			return state;
		}

		case 'submit': {
			if (state.status == 'READY') {
				let {
					dispatch,
					responseRoot,
					catalogItem
				} = action.data;
				let { form, formContents } = state;

				submitForm(responseRoot, catalogItem, form.id, formContents, dispatch, state.recaptchaResponse);
				return {
					...state,
					status: 'SUBMITTING',
					error: null
				}
			}
			return state;
		}

		case 'success': {
			if (state.status == 'SUBMITTING') {
				return { ...state, status: 'DONE', error: null };
			}
			return state;
		}

		case 'error': {
			if (state.status == 'SUBMITTING') {
				return { ...state, status: 'READY', error: action.data };
			}
			return state;
		}

		case 'captcha': {
			if (state.status != 'SUBMITTING' && state.status != 'DONE') {
				return {
					...state,
					recaptchaResponse: action.data
				};
			}
			return state;
		}

		default:
			return state;
	}
}

const Form = (props: FormProps, ref: React.Ref<any>) => {
	let [ state, dispatch ] = useReducer(FormReducer, {
		formContents: {},
		form: null,
		status: 'LOADING',
		error: null,
		recaptchaResponse: null
	});

	let actionContext = useContext(ActionContext);

	useEffect(() => {
		(async () => {
			let req = await fetch(props.endpoints.form + '/form/' + props.form);
			let json = await req.json();
			dispatch({ action: 'setForm', data: json })
		})();
	}, [ props.endpoints.form, props.form ]);

	let onSubmit = useCallback(event => {
		event.preventDefault();
		dispatch({
			action: 'submit',
			data: {
				responseRoot: props.endpoints.response,
				target: props.endpoints.response + '/submit',
				catalogItem: props.catalogItem,
				dispatch
			}
		});
	}, [ props.endpoints.response, props.catalogItem ]);

	let [captchaNode, setCaptchaNode] = useState(null);
	let theme = useTheme();

	useEffect(() => {
		if (captchaNode) recaptchaState.then(() => {
			grecaptcha.render(captchaNode, {
				sitekey: props.recaptchaKey,
				theme: props.captchaTheme == 'current' ? theme.palette.type : props.captchaTheme,
				callback: response => {
					dispatch({ action: 'captcha', data: response });
				},
				'expired-callback': () => { dispatch({ action: 'captcha', data: null }); }
			});
		});
	}, [captchaNode]);

	console.log(state);

	if (state.status == 'LOADING') return null;

	let submit;
	if (actionContext?.target) {
		submit = (
			<div className={props.classes.submit}>
				{getChildren(
					flattenChildren(props.button).map(v => ({ ...v, link: { internal: true, handler: onSubmit } }))
				)}
			</div>
		);
	} else {
		submit = (
			<div className={props.classes.submit}>
				{getChildren(
					flattenChildren(props.button)
				)}
			</div>
		);
	}

	let content: OpaqueContent;

	if (state.status == 'DONE') {
		content = flattenChildren(props.content).length ? props.content : props.altContent;
	}
	return (
		<form onSubmit={onSubmit} className={props.classes.root}>
			{state.status == 'DONE' && getChildren(content)}
			{state.status != 'DONE' && (<>
				{props.instructions && getChildren(props.instructions)}
				{state.error && 'general' in state.error && (
					<Typography className={props.classes.generalError} variant="subtitle1" color="error">
						{state.error.message}
					</Typography>
				)}
				{state.form.questions.map(question => (
					<Question
						state={state}
						error={(state.error && 'question' in state.error && state.error.question == question.id) ? state.error : null}
						status={state.status} 
						key={question.id}
						classes={props.classes} 
						dispatch={dispatch} 
						{...question} 
						value={state.formContents[question.id] || ""}
					/>
				))}
				{props.recaptchaKey && (
					<>
						<div className={clsx("g-recaptcha", props.classes.field, props.classes.captchaField)} ref={setCaptchaNode}/>
						{(state.error && 'errorCode' in state.error && state.error.errorCode == 'INVALID_CAPTCHA') ? (
							<Typography variant="subtitle1" className={props.classes.captchaError} color="error">
								Please make sure the reCAPTCHA above is done.
							</Typography>
						) : null}
					</>
				)}
				{actionContext?.target ? ReactDOM.createPortal(submit, actionContext.target) : submit}
			</>)}
		</form>
	);
}

export default ThemeLoader(
	StyleLoader(
		Form,
		makeStyles(
			theme => ({
				root: {
					display: 'flex',
					flexDirection: 'column',
					'& $root $field': {
						marginTop: theme.spacing(1),
						marginBottom: theme.spacing(1)
					}
				},
				field: {
					marginTop: theme.spacing(2),
					marginBottom: theme.spacing(2)
				},
				captchaError: {
					marginBottom: theme.spacing(1)
				},
				...(Object.keys(QuestionTypes).reduce((acc: Record<string, CSSProperties>, type) => {
					let Q = QuestionTypes[type].style;
					let R: Record<string, CSSProperties>;

					if (typeof Q == 'function') {
						R = Q(theme);
					} else {
						R = Q;
					}

					return {
						...acc,
						...R
					};
				}, {})),
				submit: {
					flex: 0,
					marginTop: theme.spacing(3),
					marginLeft: 'auto',
					marginRight: 'auto'
				}
			}),
			{ name: 'SWForm' }
		)
	)
);
