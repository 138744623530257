import React, { useState, useEffect } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import HostedVideoProps from './props';
import 'intersection-observer';

const HostedVideoBlock = ({ classes, videoProvider, videoId, insertionTime }: HostedVideoProps, ref: React.Ref<HTMLDivElement>) => {
	const [visible, setVisible] = useState(insertionTime == 'initial');
	const [el, setEl] = useState<HTMLDivElement>(null);
	const [savedBody, setSavedBody] = useState([ videoProvider, videoId ]);
	useEffect(() => {
		if (insertionTime == 'initial' || insertionTime == 'afterLoad')
			setVisible(true);
		else if (el && !visible) {
			let io = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					setVisible(true);
					io.unobserve(el)
					io = null;
				}
			});
			io.observe(el);
			return () => {
				io?.unobserve(el);
			}
		}
	}, [insertionTime, el, visible]);
	useEffect(() => {
		setSavedBody([videoProvider, videoId]);
	}, [videoProvider, videoId]);
	useEffect(() => {
		if (el && visible && insertionTime != 'initial') {
			let x = document.createElement('div');
			x.innerHTML = constructEmbedCodeForVideo(videoProvider, videoId, classes.iframe);
			el.innerHTML = '';
			while (x.firstChild) el.appendChild(x.firstChild);
		}
		if (ref) {
			if (typeof ref == 'function') ref(el);
			else (ref as any).current = el;
		}
	}, [el, visible, insertionTime, savedBody]);
	
	return (
		<div className={classes.root} ref={setEl} {...(visible ? { dangerouslySetInnerHTML: { __html: constructEmbedCodeForVideo(videoProvider, videoId, classes.iframe) } } : {})} />
	);
};

export default ThemeLoader(
	StyleLoader(
		HostedVideoBlock,
		makeStyles(
			theme => ({
				root: {
					position: 'relative',
					paddingTop: '56.25%'
				},
				iframe: {
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: '100%',
					height: '100%'
				},
			}),
			{ name: 'SWHostedVideo' }
		)
	)
);

function constructEmbedCodeForVideo(provider: 'youtube' | 'vimeo', id: string, className: string): string {
	switch (provider) {
		case 'youtube':
			return `<iframe width='560' height='315' src='https://www.youtube.com/embed/${id}' class='${className}' frameborder='0' allowfullscreen></iframe>`;
		case 'vimeo':
			return `<iframe width='560' height='315' src='https://player.vimeo.com/video/${id}' class='${className}' frameborder='0' allowfullscreen></iframe>`;
	}
}