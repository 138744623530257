import { Animation } from "./Animation";

export function animateToSelector(sel: string, duration: number = 300, func: string = 'ease', target: { scrollTop: number } = document.scrollingElement || document.documentElement) {
	var S = $(sel).offset().top - $(target).offset().top;
	var T = target;
	var OT = T.scrollTop;
	var D = S - OT;
	var A = new Animation(duration, func, pct => {
		(target).scrollTop = OT + (D * pct);
	});
	A.start();
}
