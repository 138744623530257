import { Animation } from './Animation';

export function animateToElement(element: HTMLElement, container: HTMLElement, duration: number = 300, func: string = 'ease') {
	container.style.position = 'relative';
	var S = element.offsetTop;
	var OT = container.scrollTop;
	var D = S - OT;
	var A = new Animation(duration, func, pct => {
		container.scrollTop = OT + (D * pct);
	});
	container.style.position = '';
	A.start();
}
