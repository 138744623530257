export class TimingFunction {
	alwaysIncreasing = true;

	constructor(args: string[]) {

    }
	
	_value(percent: number) {
		return percent;
	}
	
	value(percent: number) {
		return this._value(percent);		
	}
	
	static functions: Record<string, typeof TimingFunction> = {
        linear: TimingFunction
    };
	static instances: Record<string, TimingFunction> = {};

    static for(name: string): TimingFunction {
		if (TimingFunction.functions[name]) return new TimingFunction.functions[name]([]);
		if (name.indexOf('(') == -1) return TimingFunction.for('step-start');
		var x = name.substring(0, name.indexOf('('));
		if (TimingFunction.functions[x]) {
			var argListS = name.substring(name.indexOf('(') + 1, name.indexOf(')') + 1);
			var argList = argListS.split(',').map(v => v.replace(/^\s*/, '').replace(/\s*$/, '')).map(v => isNaN(Number(v)) ? v : Number(v)).map(String);
			return new TimingFunction.functions[x](argList);
		}
		return new TimingFunction.functions['step-start']([]);
	}
}
