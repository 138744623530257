import React from 'react';
import { CarouselProps } from './CarouselProps';
import { CarouselComponent } from './CarouselComponent';
import { useStyles } from './useStyles';
import clsx from 'clsx';
import { ErrorBoundary } from './ErrorBoundary';

const Carousel = React.forwardRef(function Carousel(props: React.PropsWithChildren<CarouselProps>, ref: React.Ref<CarouselComponent>): JSX.Element {
	const styles = useStyles();
	const tgtStyles: ReturnType<typeof useStyles> = React.useMemo(() => {
		let res: ReturnType<typeof useStyles> = {...styles};
		if (props.classes) {
			for (let key of (Object.keys(props.classes) as Array<keyof typeof res>)) {
				res[key] = clsx(res[key], props.classes[key]);
			}
		}
		return res;
	}, [styles, props.classes]);

	return <ErrorBoundary><CarouselComponent {...props} classes={tgtStyles} ref={ref} /></ErrorBoundary>
});

export { Carousel };
