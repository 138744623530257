import React, { useContext, useCallback, useState, useEffect, useRef, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import { CSSProperties } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import getChildren from '../../utils/children';
import useBreakpoint from '../../utils/useBreakpoint';
import FlexGridProps from './props';
import { BlockPropsBase } from '../..';

const useTransition = (): [boolean, () => void, () => void] => {
	const [transitioning, setTransitioning] = useState(false);
	const isTransitioning = useCallback(() => setTransitioning(true), []);
	const doneTransitioning = useCallback(() => setTransitioning(false), []);
	return [transitioning, isTransitioning, doneTransitioning];
};

const Dummy = () => {};
const InternalItemSpec = `SwGridContainerInternalItem`;

const SwGridContainer = (props: FlexGridProps, ref: React.Ref<any>) => {
	const bp = useBreakpoint();

	const node = useRef(null);
	const spacer = useRef(null);
	const [itemInfo, setItemInfo] = useState({ multiple: true, style: {}, outerStyle: {} });
	const [transitioning, setIsTransitioning, setDoneTransitioning] = useTransition();
	const [filterBar, setFilterBar] = useState(null);

	const itemsYPos: number[] = [];

	useEffect(() => {
		const resize = () =>
			requestAnimationFrame(() => {
				if (!spacer.current) {
					return;
				}
				const items = spacer.current.querySelectorAll(`.${InternalItemSpec}`);
				const itemWidth = items[0].getBoundingClientRect().width;
				const fitsThree = items[0].getBoundingClientRect().left < items[2].getBoundingClientRect().left;

				setItemInfo({
					style: {
						'--column-size': `${itemWidth}px`,
					},
					outerStyle: {
						minHeight: filterBar ? `calc(var(--selected-item-margin) + ${filterBar.offsetHeight}px)` : void 0,
					},
					multiple: fitsThree,
				});
			});

		window.addEventListener('resize', resize);
		resize();
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [props.classes.internalItem, filterBar]);

	let Wrap: typeof React.Fragment = Fragment;

	// console.log(props.id, ': ', usedContext ? usedContext.filterInDrawer : null);

	return (
		<Wrap>
			<Box
				className={clsx(props.classes.root, props.classes[bp], {
					[props.classes.marginOutside]: props.marginOutside,
					[props.classes.flex]: props.flex,
					[props.classes.xsContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.xs) != -1,
					[props.classes.smContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.sm) != -1,
					[props.classes.mdContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.md) != -1,
					[props.classes.lgContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.lg) != -1,
					[props.classes.xlContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.xl) != -1,
					[props.classes.xsFill]: props.size.xs == '100%',
					[props.classes.smFill]: props.size.sm == '100%',
					[props.classes.mdFill]: props.size.md == '100%',
					[props.classes.lgFill]: props.size.lg == '100%',
					[props.classes.xlFill]: props.size.xl == '100%',
					[props.classes.verticalCenterContent]: props.verticalCenter,
					[props.classes.fixed]: props.expand === false
				})}
				style={{
					'--item-size-xs': props.size.xs,
					'--item-size-sm': props.size.sm,
					'--item-size-md': props.size.md,
					'--item-size-lg': props.size.lg,
					'--item-size-xl': props.size.xl,
					'--item-margin-xs': props.margin.xs,
					'--item-margin-sm': props.margin.sm,
					'--item-margin-md': props.margin.md,
					'--item-margin-lg': props.margin.lg,
					'--item-margin-xl': props.margin.xl,
					minHeight: props.minHeight,
					...itemInfo.outerStyle,
				} as CSSProperties}
			>
				<div
					className={clsx(props.classes.inner, props.classes.columnSpacer, {
						[props.classes.gridStretch]: props.fillGrid,
						[props.classes.nowrap]: props.nowrap,
					})}
					ref={spacer}
					role="presentation"
				>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
				</div>
					<div
						ref={node}
						onTransitionEnd={setDoneTransitioning}
						className={clsx(props.classes.inner, {
							[props.classes.alignVertically]: props.centerVertically,
							[props.classes.gridStretch]: props.fillGrid,
							[props.classes.transitioning]: transitioning,
							[props.classes.multiple]: itemInfo.multiple,
							[props.classes.nowrap]: props.nowrap,
						})}
						style={itemInfo.style}
					>
						{getChildren(props.content, (node, child, index) => <GridWrapper props={props} node={node} child={child} key={index} itemsYPos={itemsYPos} />)}
				</div>
			</Box>
		</Wrap>
	);
};

let GridWrapper = (p0: { props: FlexGridProps, node: React.ReactElement, child: BlockPropsBase, itemsYPos: number[] }): JSX.Element => {
	let { props, node, child, itemsYPos } = p0;

	const gridItem = useRef(null);
	const [toggle, setToggle] = useState(false);
	let Animate: typeof Fragment | ((props: Record<string, any>) => JSX.Element) = Fragment;
	let animateProps = {};
	if (
		node.props.type !== 'button' &&
		props.animationType !== 'none'
	) {
		const modules = {
			fade: Fade,
			zoom: Zoom,
			grow: Grow
		};
		Animate = modules[props.animationType || 'fade'];
		if (Animate) {
			animateProps = {
				in: toggle,
				timeout: 1000,
			};
		} else {
			Animate = Fragment;
		}
	}

	useEffect(() => {
		if (props.animationType === 'none') return;
		const el = gridItem.current;
		const maxDelay = 500;
		const handleToggle = (columnIndex: number) => {
			const timeout = (maxDelay / itemsYPos.length) * columnIndex;
			setTimeout(() => {
				setToggle(true);
			}, timeout);
		};

		if (itemsYPos.length < 1 || el.offsetLeft > itemsYPos[itemsYPos.length - 1])
			itemsYPos.push(el.offsetLeft);

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) handleToggle(itemsYPos.indexOf(el.offsetLeft));
			});
		});
		observer.observe(el);
		// eslint-disable-next-line consistent-return
		return () => observer.unobserve(el);
	}, []);

	return (
		<Animate {...animateProps} key={child.id}>
			<div style={{ visibility: void 0 }}
				className={clsx(
					props.classes.item,
					// props.classes.alignVertically,
					props.classes.internalItem,
					InternalItemSpec,
					{
						[props.classes.fillGrid]: props.fillGrid,
						[props.classes.fillItemSpace]: props.fillItemSpace,
					}
				)}
				ref={gridItem}
			>
				{node}
			</div>
		</Animate>
	);
}

export default ThemeLoader(
	StyleLoader(
		SwGridContainer,
		makeStyles(
			theme => ({
				root: {
					'--selected-item-size': '100%',
					'--selected-item-margin': '0',
					'--column-size': 'var(--selected-item-size)',
					position: 'relative',
				},
				flex: {
					'& $item': {
						maxWidth: 'var(--selected-item-size)'
					}
				},
				fixed: {},
				item: {
					flex: '1 0 calc(var(--selected-item-size) - var(--selected-item-margin))',
					margin: 'calc(var(--selected-item-margin) * 0.5)',
					'&$itemSpacer': {
						marginTop: 0,
						marginBottom: 0,
					},
					'$xs$xsContent > $inner &, $sm$smContent > $inner &, $md$mdContent > $inner &, $lg$lgContent > $inner &, $xl$xlContent > $inner &': {
						flex: '1 0 var(--selected-item-size)',
					},
					'$fixed$xs$xsContent > $inner &, $fixed$sm$smContent > $inner &, $fixed$md$mdContent > $inner &, $fixed$lg$lgContent > $inner &, $fixed$xl$xlContent > $inner &': {
						flexGrow: 0,
						flexShrink: 0
					},
					'@supports (display: grid)': {
						'$xs:not($xsContent) > $inner &, $sm:not($smContent) > $inner &, $md:not($mdContent) > $inner &, $lg:not($lgContent) > $inner &, $xl:not($xlContent) > $inner &': {
							margin: 0,
							flex: 'auto',
						},
					},
				},
				internalItem: {},
				fillGrid: {
					display: 'flex',
					flexDirection: 'column',
					'& > *': {
						flex: '1 1 100%',
					},
				},
				fillItemSpace: {
					height: '100%',
					width: '100%',
				},
				alignVertically: {
					'& [class*=SwGridContainer-item-]': {
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					},
				},
				filterBar: {
					position: 'absolute',
					top: 'calc(var(--selected-item-margin) * 0.5)',
					left: 'calc(var(--selected-item-margin) * 0.5)',
					width: 'var(--column-size)',
					// bottom: 'calc(var(--selected-item-margin) * 0.5)',
					transform: 'translateX(calc(0px - (1.5 * var(--selected-item-margin)) - var(--column-size)))',
					transition: theme.transitions.create('transform'),
				},
				filterBarInner: {
					visibility: 'hidden',
				},
				marginOutside: {},
				inner: {
					/*
					'& [class*=SwGridContainer-item-]': {
						'& .MuiButton-root:last-child': {
							marginRight: theme.spacing(1),
						},
					},
					*/
					padding: 0,
					'$marginOutside &': {
						padding: 'calc(0.5 * var(--selected-item-margin))',
						'&$columnSpacer': {
							paddingTop: 0,
							paddingBottom: 0,
						},
					},
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					'@supports (display: grid)': {
						'$xs:not($xsContent) > &, $sm:not($smContent) > &, $md:not($mdContent) > &, $lg:not($lgContent) > &, $xl:not($xlContent) > &': {
							display: 'grid',
							gridTemplateColumns:
								'repeat(auto-fill, var(--selected-item-size))',
							gridAutoFlow: 'row',
							gridColumnGap: '0',
							gridRowGap: 'var(--selected-item-margin)',
							marginLeft: 'calc(-1 * var(--selected-item-margin))',
							'& $item': {
								margin: 0,
								paddingLeft: 'var(--selected-item-margin)',
							},
							'&$transitioning': {
								gridTemplateColumns: 'repeat(auto-fill, var(--column-size))',
							},
							'&$columnSpacer': {
								gridRowGap: 0,
							},
						}
					},
					'&$withFilter': {
						position: 'relative',
						transition: theme.transitions.create('padding-left'),
						'&$filterOpen': {
							paddingLeft: 'var(--column-size, 250px)',
							'$marginOutside &': {
								paddingLeft: 'calc(var(--column-size, 250px) + (1.5 * var(--selected-item-margin)))',
							},
						},
					},
					'&$transitioning $item': {
						flexGrow: '0 !important',
						flexBasis: 'var(--column-size)',
					},
				},
				transitioning: {
					'& $filterBarInner': {
						visibility: 'visible',
					},
				},
				withFilter: {},
				filterOpen: {
					'& $filterBar': {
						transform: 'translateX(0px)',
					},
					'& $filterBarInner': {
						visibility: 'visible',
					},
				},
				gridStretch: {
					'@supports (display: grid)': {
						alignItems: 'stretch',
					},
				},
				xs: {
					'--selected-item-size': 'var(--item-size-xs, 100%)',
					'--selected-item-margin': 'var(--item-margin-xs, 0px)',
				},
				sm: {
					'--selected-item-size': 'var(--item-size-sm, var(--item-size-xs, 100%))',
					'--selected-item-margin': 'var(--item-margin-sm, var(--item-margin-xs, 0px))',
				},
				md: {
					'--selected-item-size': 'var(--item-size-md, var(--item-size-sm, var(--item-size-xs, 100%)))',
					'--selected-item-margin': 'var(--item-margin-md, var(--item-margin-sm, var(--item-margin-xs, 0px)))',
				},
				lg: {
					'--selected-item-size': 'var(--item-size-lg, var(--item-size-md, var(--item-size-sm, var(--item-size-xs, 100%))))',
					'--selected-item-margin': 'var(--item-margin-lg, var(--item-margin-md, var(--item-margin-sm, var(--item-margin-xs, 0px))))',
				},
				xl: {
					'--selected-item-size': 'var(--item-size-xl, var(--item-size-lg, var(--item-size-md, var(--item-size-sm, var(--item-size-xs, 100%)))))',
					'--selected-item-margin': 'var(--item-margin-xl, var(--item-margin-lg, var(--item-margin-md, var(--item-margin-sm, var(--item-margin-xs, 0px)))))',
				},
				xsContent: {},
				smContent: {},
				mdContent: {},
				lgContent: {},
				xlContent: {},
				xsFill: {},
				smFill: {},
				mdFill: {},
				lgFill: {},
				xlFill: {},
				multiple: {},
				nowrap: {
					whiteSpace: 'nowrap',
					flexWrap: 'nowrap',
					overflow: 'auto',
				},
				filterButton: {
					position: 'sticky',
					left: 'calc(var(--selected-item-margin) * -0.5)',
					margin: 'calc(var(--selected-item-margin) * -0.5)',
					marginRight: 'calc(var(--selected-item-margin) * 0.5)',
					padding: 'var(--selected-item-margin)',
					backgroundColor: theme.palette.background.default,
					zIndex: 1,
				},
				itemSpacer: {},
				columnSpacer: {},
				drawer: {
					'& $filterBarInner': {
						visibility: 'visible',
						padding: theme.spacing(3.5),
						paddingTop: 0,
					},
				},
				drawerPaper: {
					maxWidth: '400px',
					width: '100%',
				},
				drawerHeader: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					padding: theme.spacing(2),
					flex: '0 0 auto',
				},
				drawerText: {
					flex: '1 1 auto',
					padding: theme.spacing(1.5),
				},
				verticalCenterContent: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				},
				filterInDrawer: {}
			}),
			{ name: 'SwGridContainer' }
		)
	)
);

