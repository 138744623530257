declare global {
	interface EventListenerOptions {
		passive?: boolean;
	}
}

import { Carousel } from './carousel/Carousel';
export default Carousel;

import { CarouselProps } from './carousel/CarouselProps';
export { CarouselProps };

import { CarouselComponent } from './carousel/CarouselComponent';
export { CarouselComponent };
