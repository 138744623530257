import { CubicBezier } from "./CubicBezier";
import { TimingFunction } from "./TimingFunction";

class EaseOut extends CubicBezier {
	constructor() { super([0, 0, .58, 1]); }

	alwaysIncreasing = true;
}

TimingFunction.functions['ease-out'] = EaseOut
export { EaseOut };
