import { Steps } from './Steps';
import { TimingFunction } from './TimingFunction';

class StepStart extends Steps {
	constructor() { super([1, 'start']); }

	alwaysIncreasing = true;
}

TimingFunction.functions['step-start'] = StepStart;
export { StepStart };

