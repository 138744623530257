import { CubicBezier } from "./CubicBezier";
import { TimingFunction } from "./TimingFunction";

class EaseIn extends CubicBezier {
	constructor() { super([0.42, 0, 1, 1]) }

	alwaysIncreasing = true;
}

TimingFunction.functions['ease-in'] = EaseIn;

export { EaseIn }