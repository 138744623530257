import * as path from 'path';
import { inspect } from 'util';

export interface ModulePackageJsonOld {
	readonly location: string;
	readonly moduleId: string;
	readonly identity: string;
	readonly tmpDir?: string;
	readonly fileDir?: string;
}

export interface ModulePackageJson {
	readonly fileDir: string;
	readonly tmpDir: string;
	readonly target: string;
	readonly moduleId: string;
}

const PackageMap: Map<string, ModulePackage> = new Map();

// Dynamic import of the module.
// Inside of node, the 'module' prop is used.
// When sending this to the client, it gets turned into the object described in toJSON(), which only contains the web-facing bits.
export class ModulePackage implements ModulePackageJson{
	constructor(
		public fileDir: string, 
		public tmpDir: string,
		public target: string,
		public moduleId: string) {
		if (process.env.REAL_BROWSER != 'true') {
			if (fileDir == './public/lib') this.fileDir = fileDir = require('@sightworks/config').baseDir + '/public/lib';
			if (tmpDir == './public/lib') this.tmpDir = tmpDir = require('@sightworks/config').baseDir + '/tmp';
		}
		if (PackageMap.has(`${fileDir}:${tmpDir}:${target}:${moduleId}`)) {
			return PackageMap.get(`${fileDir}:${tmpDir}:${target}:${moduleId}`);
		}
		PackageMap.set(`${fileDir}:${tmpDir}:${target}:${moduleId}`, this);
	}

	get location(): string {
		return `/public/lib/${this.target}.bundle.js`;
	}

	get file(): string {
		let r = path.join(this.fileDir, this.target);
		if (!r.startsWith('/')) return `./${r}`;
		return r;
	}

	get module(): string {
		let r = path.join(this.tmpDir, this.target);
		if (!r.startsWith('/')) return `./${r}`;
		return r;
	}

	get identity(): string {
		return this.moduleId;
	}
	
	toJSON(): ModulePackageJson {
		let { fileDir, tmpDir, target, moduleId } = this;
		return { fileDir, tmpDir, target, moduleId };
		// return { location: this.location, moduleId: `./public/lib/${this.target}`, identity: this.moduleId };
	}

	static fromJSON(json: ModulePackageJson): ModulePackage {
		let { fileDir, tmpDir, target, moduleId } = json;
		if (process.env.REAL_BROWSER == 'true') {
			fileDir = tmpDir = './public/lib/';
		}
		return new ModulePackage(fileDir, tmpDir, target, moduleId);
	}

	[inspect.custom]() {
		return [
			`ModulePackage(${this.location}) {`,
			`    fileDir = ${this.fileDir}`,
			`    tmpDir = ${this.tmpDir}`,
			`    target = ${this.target}`,
			`    moduleId = ${this.moduleId}`,
			`    [computed] location = ${this.location}`,
			`    [computed] file = ${this.file}`,
			`    [computed] module = ${this.module}`,
			`    [computed] identity = ${this.identity}`,
			`}`
		].join('\n')
	}
}
