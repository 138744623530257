import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ThemeLoader } from '@sightworks/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '../../components/DialogContent';
import DialogTitle from '../../components/DialogTitle';
import DialogActions from '../../components/DialogActions';
import getChildren from '../../utils/children';
import Provider from '../../utils/linkContext';
import ActionContextProvider, { Context as ActionContext } from '../../utils/actionContext';
import DialogProps from './props';
import { BlockPropsBase } from '../..';
import { Context } from '../dialog-container/context';
import PauseWrapper, { PauseState, PauseContext } from '../../utils/pauseContext';

type DialogActionsProps = {
	onClose: React.MouseEventHandler<HTMLElement>
}

const CustomDialogActions = ({ onClose }: DialogActionsProps) => {
	return (
		<DialogActions onClose={onClose} className="MuiTypography-srOnly"/>
	);
};

type SWDialogProps = BlockPropsBase & {
	open: boolean;
	close: () => void;
	dialogTitle: string;
	dialogWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	content: BlockPropsBase[];
	dialogTitleText: string;
}

let SWDialog = ({ open, close, dialogTitle, dialogWidth, content }: SWDialogProps, ref: React.Ref<any>) => (
	<Dialog open={open} onClose={close} maxWidth={dialogWidth} fullWidth ref={ref}>
		<PauseWrapper>
			<DialogTitle onClose={close}>{dialogTitle}</DialogTitle>
			<DialogContent>{getChildren(content)}</DialogContent>
			<CustomDialogActions onClose={close} />
		</PauseWrapper>
	</Dialog>
);

let ThemedDialog = ThemeLoader(SWDialog);

const DialogBlock = (props: DialogProps, ref: React.Ref<any>) => {
    const dialogContext = useContext(Context);
	const pauseContext = useContext(PauseContext);
	const close = useCallback(() => { dialogContext.dispatch({ type: 'close' }) }, [dialogContext.dispatch]);
    const open = dialogContext.open;

	useEffect(() => {
		pauseContext.toggle(open);
	}, [open]);

	return <ThemedDialog open={open} close={close} dialogTitle={props.dialogTitleText} dialogWidth={props.dialogWidth} content={props.content} useNamedTheme="default"/>
};

const ThemedDialogBlock = ThemeLoader(DialogBlock);
export default ThemedDialogBlock;
