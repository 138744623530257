import { TimingFunction } from "./TimingFunction";
import { CubicBezier } from "./CubicBezier";

class Ease extends CubicBezier {
    constructor() { super([0.25, 0.1, 0.25, 1]); }

	alwaysIncreasing = true;
}

export { Ease };
TimingFunction.functions.ease = Ease;