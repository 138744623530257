import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { CSSProperties } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import useBreakpoint, { BreakpointValue } from '../../utils/useBreakpoint';
import * as Portal from '../../utils/portal';
import getChildren, { concatChildren, flattenChildren, emptyChildren } from '../../utils/children';
import Hidden, { HiddenElement } from '../hidden/block';
import SiteNavigationProps from './props';
import { BlockPropsBase } from '../..';

function useCurrentMedia() {
	const bk: BreakpointValue[] = ['xs', 'sm', 'md', 'lg', 'xl'];
	const bp = useBreakpoint();
	return [
		bp,
		(b: BreakpointValue | 'none') => {
			if (b == 'none') return true;
			const i = bk.indexOf(b);
			return i != -1 && i <= bk.indexOf(bp);
		},
		(b: BreakpointValue | 'none') => {
			if (b == 'none') return true;
			const i = bk.indexOf(b);
			return i != -1 && i + 1 <= bk.indexOf(bp);
		},
	];
}

const SiteNavBlock = (
	{ /* initialHeight, */ content, classes, siteLogo, smallSiteLogo, siteLogoAltText, switchLogo }: SiteNavigationProps,
	ref: React.Ref<any>
) => {
	const [media, isMedia, isAboveMedia] = useCurrentMedia();

	const [height, setHeight] = useState('auto');
	const [rootNode, setRootNode] = useState<HTMLElement>(null);
	const [cbRef] = useState(() => (node: HTMLElement) => {
		setRootNode(node);
		if (node) setHeight(`${node.offsetHeight}px`);
	});
	const [root, setRoot] = useState('/');
	useEffect(() => {
		setRoot(document.querySelector('base').getAttribute('href'));
		const resize = () => {
			setRootNode(node => {
				if (node) setHeight(`${node.offsetHeight}px`);
				return node;
			});
		};
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	});
	const [hamburger, setHamburger] = useState(false);
	const [hamburgerDone, setHamburgerDone] = useState(true);

	useEffect(() => {
		setHamburgerDone(false);
		const t = setTimeout(() => {
			setHamburgerDone(true);
		}, 300);
		return () => clearTimeout(t);
	}, [hamburger]);
	let c0 = flattenChildren(content);
	const beforeToolbar = c0.filter(node => node.siteNavigationOutsideToolbar);
	const insideToolbar = c0.filter(node => !node.siteNavigationOutsideToolbar);

	const { siteLogo: siteLogoClass, drawer, ...otherClasses } = classes;

	let minHamburgerBreakpoint: BreakpointValue = null;
	const bps: BreakpointValue[] = ['xs', 'sm', 'md', 'lg', 'xl'];
	type BpType = BreakpointValue | 'none';
	const below = (bp: BpType) => (bp == 'none' ? [] : bps.slice(0, bps.indexOf(bp)));
	const above = (bp: BpType) => (bp == 'none' ? bps : bps.slice(bps.indexOf(bp) + 1));
	const below1 = (bp: BpType) => (bp == 'none' ? [] : bps.slice(0, bps.indexOf(bp) + 1));
	const above1 = (bp: BpType) => (bp == 'none' ? bps : bps.slice(bps.indexOf(bp) + 2));

	const hamburgerItems: BlockPropsBase[] = [];
	// let exposed = [];

	insideToolbar.forEach(node => {
		if ('hamburgerBp' in node) {
			if (node.hamburgerBp == 'none') return;
			hamburgerItems.push(node);
			if (!minHamburgerBreakpoint) minHamburgerBreakpoint = node.hamburgerBp;
			else if (bps.indexOf(minHamburgerBreakpoint) < bps.indexOf(node.hamburgerBp))
				minHamburgerBreakpoint = node.hamburgerBp;
		}
	});

	// let alertsBanner = content.find(node => node.type == 'alert-banner');
	let style : CSSProperties = {};
	if (hamburger || !hamburgerDone) style.zIndex = 1301;
	let sizes = useMemo(() => {
		let sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
		let pos = sizes.indexOf(switchLogo);
		return { small: sizes.slice(0, pos), large: sizes.slice(pos) };
	}, [switchLogo]);
	
	return (
		<>
			<AppBar
				color="default"
				elevation={0}
				position="sticky"
				square
				ref={cbRef}
				classes={otherClasses}
				component="nav"
				style={style}
			>
				<Portal.Wrapper>
					<Paper elevation={hamburger ? 0 : 2} square style={{ zIndex: 1150 }}>
						{beforeToolbar.length > 0 && getChildren(beforeToolbar)}
						<Toolbar ref={ref} disableGutters role={insideToolbar.find(v => v.type == 'nav-drawer') ? 'tablist' : void 0}>
							{hamburgerItems.length > 0 && (
								<HiddenElement hide={above(minHamburgerBreakpoint)}>
									<IconButton color="inherit" aria-label="Menu Toggle" onClick={() => setHamburger(!hamburger)}>
										<Icon>{hamburger ? 'close' : 'menu'}</Icon>
									</IconButton>
								</HiddenElement>
							)}
							{siteLogo || smallSiteLogo || siteLogoAltText ? (
								<>
									{smallSiteLogo == siteLogo ? (
										<ButtonBase focusRipple href={root} title='Home'>
											<img src={siteLogo} alt={siteLogoAltText} className={siteLogoClass} />
										</ButtonBase>
									) : (<>
										<HiddenElement hide={sizes.large}>
											<ButtonBase focusRipple href={root} title="Home">
												<img src={smallSiteLogo || siteLogo} alt={siteLogoAltText} className={siteLogoClass} />
											</ButtonBase>
										</HiddenElement>
										<HiddenElement hide={sizes.small}>
											<ButtonBase focusRipple href={root} title="Home">
												<img src={siteLogo || smallSiteLogo} alt={siteLogoAltText} className={siteLogoClass} />
											</ButtonBase>
										</HiddenElement>
									</>)}
								</>
							) : null}
							{insideToolbar.map(item => {
								return item.hamburgerBp ? (
									<Hidden hide={below1(item.hamburgerBp)} key={item.id} content={concatChildren(emptyChildren(), [item])} omit />
								) : (
										getChildren([item])[0]
									);
							})}
						</Toolbar>
					</Paper>
				</Portal.Wrapper>
			</AppBar>
			{hamburgerItems.length > 0 && (
				<HiddenElement hide={above(minHamburgerBreakpoint)} omit onHidden={() => setHamburger(false)}>
					<Drawer anchor="left" open={hamburger} onClose={() => setHamburger(false)} classes={{ root: drawer }}>
						{hamburgerItems.map(item => (
							<Hidden hide={above(item.hamburgerBp)} key={item.id} content={concatChildren(emptyChildren(), [{ ...item, hamburger: true }])} />
						))}
						<div
							id="site-nav-spacer-div"
							style={{
								height: (height && height != 'auto') ? `calc(${height} + var(--extra, 0px))` : 'auto',
								minWidth: '284px',
								order: -1,
								flex: '0 0 auto',
								marginBottom: '1px',
							}}
							className="MuiPaper-root MuiPaper-elevation1"
							role="presentation"
						/>
					</Drawer>
				</HiddenElement>
			)}
			{/* <div style={{ height: height || 'auto' }} role="presentation" /> */}
		</>
	);
};

export default ThemeLoader(
	StyleLoader(
		SiteNavBlock,
		makeStyles(
			theme => ({
				root: {
					'& .MuiTouchRipple-root': {
						[theme.breakpoints.down('sm')]: {
							zIndex: 1000,
						},
					},
				},
				siteLogo: {
					maxWidth: 250,
					maxHeight: 49,
					margin: theme.spacing(2),
					flex: '1 1 auto',
				},
				drawer: {
					'& .MuiButton-text': {
						justifyContent: 'flex-start',
						padding: theme.spacing(3),
						paddingTop: theme.spacing(2),
						paddingBottom: theme.spacing(2),
						'& .MuiTypography-button': {
							textTransform: 'none',
							...theme.typography.body2,
						},
					},
				},
			}),
			{ name: 'SiteNavigationBlock' }
		)
	)
);
