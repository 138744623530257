import React, { useCallback } from 'react';
import { useBreakpoint } from '@sightworks/block';
import MuiTextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { FieldProps } from '../../registry';

const TextField = ({ dispatch, id, error, title, classes, value }: FieldProps) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x: { error?: boolean, helperText?: string } = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}

	return (
		<MuiTextField variant='filled' label={title} className={clsx(classes.field, classes.textField)} value={value} onChange={callback} inputProps={{ 'aria-label': title }} {...x} />
	);
}

export default TextField;
export const Style = {
	textField: {
		flex: 1
	}
};

