// Load it from the current package.
/* eslint-disable */
import { getThemeModule as getStyleModule, loadThemeModule as loadStyleModule } from '@sightworks/theme';
import { ThemeProvider, ClassNameMap, DefaultTheme, Styles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import React, { Component, Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Registry, BlockPropsBase } from '@sightworks/block';
import BackgroundLoader, { CustomBackgroundLoader } from './background';
import Typography from '@material-ui/core/Typography';
import { ModulePackage, ModulePackageJson } from '@sightworks/module';

/*
export default function makeStyles<Theme = DefaultTheme, ClassKey extends string = string>(
	style: Styles<Theme, {}, ClassKey>,
	options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
  ): (props?: any) => ClassNameMap<ClassKey>;
  export default function makeStyles<
	Theme = DefaultTheme,
	Props extends {} = {},
	ClassKey extends string = string
  >(
	styles: Styles<Theme, Props, ClassKey>,
	options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
  ): (props: Props) => ClassNameMap<ClassKey>;
*/  
type StyleModule<P extends BlockPropsBase = BlockPropsBase> = {
	default: Styles<DefaultTheme, P, string>
};

function merge(startClasses: Record<string, string> = {}, addClasses: Record<string, string> = {}) {
	let R: Record<string, Set<string>> = {};
	for (let key in startClasses) {
		R[key] = new Set();
		for (let value of startClasses[key].split(/\s+/).filter(v => !!v))
			R[key].add(value);
	}
	for (let key in addClasses) {
		if (!(key in R)) R[key] = new Set();
		for (let value of addClasses[key].split(/\s+/).filter(v => !!v))
			R[key].add(value);
	}
	
	let S: Record<string, string> = {};
	for (let [name, value] of Object.entries(R)) S[name] = [...value].join(' ');
	return S;
}

type AccessibleRootProps = React.PropsWithChildren<{
	title: string;
	id: string;
}>

const AccessibleRoot = (props: AccessibleRootProps) => {
	let [root, setRoot] = useState('');
	useEffect(() => {
		if (typeof location == 'object') setRoot(location.pathname + location.search);
	});
	let tgt = useRef<HTMLSpanElement>();
	let ref = useCallback(node => tgt.current = node, []);
	let go = useCallback(event => { event && event.preventDefault(); setTimeout(() => tgt.current.focus(), 0) }, []);
	return (
		<>
			<Typography variant="srOnly" component="a" href={`${root}#after-${props.id}`} onClick={go}>{props.title}</Typography>
			{props.children}
			<span id={`after-${props.id}`} ref={ref} tabIndex={0} />
		</>
	);
}

let LoadedStyleMap = new Map<StyleModule, (props: BlockPropsBase) => ClassNameMap<string>>();

type StyleFn<P> = (props: P) => Record<string, string>;
type PropsWithoutStyles<P> = Pick<P, Exclude<keyof P, "useStyles" | "ref">>;
type WithStylesProps<P> = PropsWithoutStyles<P> & {
	useStyles?: (props: PropsWithoutStyles<P>) => ClassNameMap<string>
};

function parseClassList(set: Record<string, string>, r: Map<string, Set<string>> = new Map()): Map<string, Set<string>> {
	for (let [ key, value ] of Object.entries(set)) {
		if (!r.has(key)) r.set(key, new Set<string>());
		let v = value.split(/\s+/);
		for (let item of v) {
			if (item)
				r.get(key).add(item);
		}
	}
	return r;
}

function maybeDebuggerBreakpoint() {
	false;
}

function mergeClassLists(...lists: Record<string, string>[]): Record<string, string> {
	let t: Map<string, Set<string>> = new Map();
	for (let item of lists) {
		parseClassList(item, t);
	}

	let res: Record<string, string> = {};
	for (let [key, set] of t) {
		res[key] = [...set].join(' ');
		if (res[key].indexOf('undefined') > -1) {
			maybeDebuggerBreakpoint();
		}
	}
	return res;
}

export default function StyleLoader<P extends BlockPropsBase>(
	parent: React.FC<P> | React.ForwardRefRenderFunction<P, any>, 
	useDefault: StyleFn<PropsWithoutStyles<P>> = null,
	customBackground: boolean = false
): React.ForwardRefExoticComponent<P> {
	let target = parent;
	if (parent.length == 2) target = React.forwardRef(parent as React.ForwardRefRenderFunction<P, any>);

	let WithStyles: React.ForwardRefExoticComponent<WithStylesProps<P> & React.RefAttributes<any>>;
	let WithStylesBase: React.FC<WithStylesProps<P>> = (props: WithStylesProps<P>, ref: React.Ref<any>) => {
		if (props.withAccessible) {
			return (
				<AccessibleRoot {...props.withAccessible} id={props.id}>
					<WithStyles {...props} withAccessible={null} ref={ref} />
				</AccessibleRoot>
			)
		}

		let { useStyles, ...rest } = props;
		let Q: PropsWithoutStyles<P> & React.RefAttributes<any> = rest as unknown as (PropsWithoutStyles<P> & React.RefAttributes<any>);
		let cl = [ rest.classes || {} ];
		let { classes } = props;
		if (useDefault) {
			cl.push(useDefault(props));
		}
		if (useStyles) {
			cl.push(useStyles(props));
		}
		Q.classes = mergeClassLists(...cl);
		Q.ref = ref;
		return React.createElement(target, Q as any);
	}

	WithStylesBase.displayName = `WithStyles(${parent.displayName || parent.name || 'Anonymous'})`;
	WithStyles = React.forwardRef(WithStylesBase as any) as unknown as typeof WithStyles;

	let Loader = (props: P, ref: React.Ref<any>) => {
		let prepare = (module: StyleModule<P>) => {
			if (module) {
				let r: (props: P) => ClassNameMap<string> = LoadedStyleMap.get(module);
				if (!r) {
					// console.log('makeStyles: ', props.useStyles.identity, props.useStyles.moduleId);
					let stf = makeStyles(module.default, { name: ModulePackage.fromJSON(props.useStyles).identity });
					LoadedStyleMap.set(module, stf);
					r = stf;
				}
				return { sheet: r };
			}
			return { sheet: null }
		}

		let [ styles, setStyles ] = useState(prepare(props.useStyles ? getStyleModule(props.useStyles) : null));

		let updateStyles = () => {
			if (props.useStyles) {
				let styleModule = getStyleModule(props.useStyles);
				if (!styleModule) {
					loadStyleModule(props.useStyles).then(styleModule => setStyles(prepare(styleModule)));
				} else {
					setStyles(prepare(styleModule));
				}
			} else {
				setStyles(prepare(null));
			}
		}

		useEffect(() => {
			updateStyles();
		}, [ props.useStyles ]);

		let { useStyles, ref: _, ...rest } = props;
		let R: WithStylesProps<P> = rest;
		let keyValue = "normal";
		if (useStyles && styles.sheet) keyValue = "custom";
		let typeRef = useRef(keyValue)
		useEffect(() => {
			if (keyValue != typeRef.current) {
				console.log(`Type of style ref for node changed`, props, typeRef.current, keyValue);
				typeRef.current = keyValue;
			}
		}, [ keyValue ]);
		return <WithStyles {...rest} ref={ref} useStyles={styles.sheet} key={keyValue} />;
	};

	//Loader.displayName = `StyleLoader(${target.displayName || parent.name || 'Anonymous'})`;
	return customBackground ? CustomBackgroundLoader(Loader) : BackgroundLoader(Loader);
}
